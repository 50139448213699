<template>
	<section class="container">
		<div class="row">
			<div class="col-12">
				<BaseTable :items="employeePensions" :fields="fields" :isBusy="false">
					<template v-slot:cell(supplier)="data">
						{{ getSupplierName(data.item.pensionId) }}
					</template>
					<template v-slot:cell(name)="data">
						<router-link
							:to="{
								name: 'CompanyEmployeePension',
								params: { pensionId: data.item.pensionId },
							}"
							>{{ getPensionName(data.item.pensionId) }}</router-link
						>
					</template>
				</BaseTable>
			</div>
		</div>
	</section>
</template>

<script>
import BaseTable from '@/modules/global/components/BaseTable'
import dateMixin from '@/mixins/dateMixin'
export default {
	name: 'CompanyEmployeePensionList',
	mixins: [dateMixin],
	components: {
		BaseTable,
	},
	data() {
		return {
			globalfilter: '',
			fields: [
				{
					key: 'name',
					label: 'Aftalenavn',
					sortable: true,
				},
				{
					key: 'supplier',
					label: 'Leverandør',
					sortable: true,
				},
			],
		}
	},
	async mounted() {
		this.$parent.$emit('updateLoader')
	},
	methods: {
		getSupplierName(pensionId) {
			var companyPension = this.getCompanyPension(pensionId)
			var supplierId = companyPension.pension.supplierId
			const supplier = this.suppliers.find((g) => g.id === supplierId)
			return supplier?.name ?? ''
		},
		getPensionName(pensionId) {
			var companyPension = this.getCompanyPension(pensionId)
			return companyPension.pension.name
		},
		getCompanyPension(pensionId) {
			return this.companyPensions.find((g) => g.id === pensionId)
		},
	},
	computed: {
		employeePensions() {
			return this.$store.getters['employeeVuex/employeePolicy'].pensions
		},
		companyPensions() {
			return this.company.pensions
		},
		companyId() {
			return this.$route.params.companyId
		},
        employeeId() {
            return this.$route.params.employeeId
        },
		company() {
			var company = this.$store.getters['employeeVuex/company']
			return company
		},
		isBusy() {
			return !this.$store.getters['employeeVuex/isReady']
		},
		suppliers() {
			return this.$store.getters['supplier/suppliers']
		},
	},
}
</script>

<style lang="scss"></style>
